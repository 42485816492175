// Change Div's on mobile

(function () {
  const mq960 = window.matchMedia("(min-width: 960px");
  const siteContainer = document.querySelector(".site-container");
  const headerLogin = document.querySelector("#header-login");

  function handleMediaQuery960(mq) {
    if (mq.matches) {
      document.querySelector(".header-col-right").appendChild(headerLogin);
    } else {
      document.querySelector(".header-col-middle").appendChild(headerLogin);
    }
  }

  function headerScroll() {
    const windowScrollTop = window.scrollY;
    const scrollThreshold = mq960.matches ? 130 : 90;
    siteContainer.classList.toggle("scrolled", windowScrollTop > scrollThreshold);
  }

  handleMediaQuery960(mq960);
  mq960.addListener(handleMediaQuery960);

  document.addEventListener("DOMContentLoaded", function () {
    headerScroll();
    window.addEventListener("scroll", headerScroll);
  });
})();

const searchButtons = document.querySelectorAll('.header-search-toggle');
const searchWrapper = document.querySelector('.header-search-wrapper');
const searchInput = document.querySelector('.header-search-input');

searchButtons.forEach(button => {
  button.addEventListener('click', function() {
    const isActive = searchWrapper.classList.contains('active');
    searchWrapper.classList.toggle('active', !isActive);
    if (!isActive) {
      searchInput.focus();
    }
  });
});

document.addEventListener('click', function(event) {
  if (!searchWrapper.contains(event.target) && !Array.from(searchButtons).some(button => button.contains(event.target))) {
    searchWrapper.classList.remove('active');
  }
});

searchWrapper.addEventListener('click', function(event) {
  event.stopPropagation();
});
