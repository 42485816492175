let hasSubLinks = document.querySelectorAll('a.has-sub');
let menuItems = document.querySelectorAll('li.menu-item-has-children');

hasSubLinks.forEach(function(link) {
  link.addEventListener('click', function(e) {
    e.preventDefault();

    let parentItem = this.closest('li');
    let isOpened = parentItem.classList.contains('opened');

    menuItems.forEach(function(item) {
      item.classList.remove('opened', 'animated');
    });

    if (!isOpened) {
      parentItem.classList.add('opened', 'animated');
    }
  });
});

document.addEventListener('click', function(e) {
  if (!e.target.closest('.menu-item-has-children')) {
    menuItems.forEach(function(item) {
      item.classList.remove('opened', 'animated');
    });
  }
});

// Hamburger
const hamburgerMenu = document.getElementById('hamburger-menu');
const headerNav = document.getElementById('header-nav');

hamburgerMenu.addEventListener('click', () => {
  if (headerNav.classList.contains('active')) {
    headerNav.classList.remove('active', 'target-show');
    hamburgerMenu.classList.remove('active');

  } else {
    headerNav.classList.add('active', 'target-show');
    hamburgerMenu.classList.add('active');
  }
});
