document.addEventListener('DOMContentLoaded', function() {
  const tableImages = document.querySelectorAll('table img');
  tableImages.forEach(img => {
    const width = img.getAttribute('width');
    const height = img.getAttribute('height');

    if (width && height) {
      img.style.width = width + 'px';
      img.style.height = height + 'px';
    }
  });
});
