document.addEventListener('DOMContentLoaded', function () {
  const faqSections = document.querySelectorAll('.schema-faq-section');

  faqSections.forEach(section => {
    const question = section.querySelector('.schema-faq-question');
    const answer = section.querySelector('.schema-faq-answer');

    question.addEventListener('click', () => {
      section.classList.toggle('open');
      answer.style.maxHeight = section.classList.contains('open') ? answer.scrollHeight + 'px' : '0';
    });

    // Set initial maxHeight for answers
    answer.style.maxHeight = '0';
  });
});
