

import './modules/menu';
import './modules/header';
import './modules/images';
import './modules/accordion';
import './utils/table';

window.addEventListener('load', () => {

});

window.addEventListener('resize', () => {
});
