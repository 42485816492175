// window.addEventListener('DOMContentLoaded', () => {
//   const images = document.querySelectorAll('.wp-block-media-text__media img, .wp-block-media-text__media video');
//
//   images.forEach((image) => {
//     const width = image.getAttribute('width');
//     const height = image.getAttribute('height');
//
//     if (width && height) {
//       image.style.width = width + 'px';
//       image.style.height = height + 'px';
//     }
//   });
// });
